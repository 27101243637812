<template>
  <div id="recoverPwd">
    <div class="header" />
    <div class="main">
      <div class="main-up">
        <div class="main-up-left">重置登录密码</div>
        <div class="main-up-right" @click="previousPage">
          <i class="main-up-right-i1 iconfont icon-fanhui" />
          <label class="main-up-right-i2">返回</label>
        </div>
      </div>
      <div class="main-below">
        <el-col :span="20" style="margin: 0px auto;">
          <el-tabs
            v-model="activeName"
            tab-position="left"
            style="height: 100%;"
          >
            <!--手机号找回-->
            <el-tab-pane label="通过手机号找回" name="phoneBack">
              <div class="main-below-tabs">
                <div class="main-below-center">
                  <div class="main-below-center-div1">
                    <!--获取手机号-->
                    <div class="main-below-center-div1-label">
                      <div class="main-below-center-div1-label-i1" :class="backdrop">1</div>
                      <div class="main-below-center-div1-label-i2">获取手机号</div>
                    </div>

                    <div class="main-below-center-div1-label0" />
                    <!--验证身份-->
                    <div class="main-below-center-div1-label1">
                      <div class="main-below-center-div1-label1-i1" :class="backdrop1">2</div>
                      <div class="main-below-center-div1-label1-i2">验证身份</div>
                    </div>

                    <div class="main-below-center-div1-label2" />

                    <!--重置密码-->
                    <div class="main-below-center-div1-label3">
                      <div class="main-below-center-div1-label3-i1" :class="backdrop2">3</div>
                      <div class="main-below-center-div1-label3-i2">重置密码</div>
                    </div>

                    <div class="main-below-center-div1-label4" />
                    <!--修改成功-->
                    <div class="main-below-center-div1-label5">
                      <div class="main-below-center-div1-label5-i1" :class="backdrop3">4</div>
                      <div class="main-below-center-div1-label5-i2" style="text-align: left;padding-left: 10px">完成</div>
                    </div>
                  </div>
                  <!--表单1-->
                  <div
                    v-if="conceal1"
                    class="main-below-center-div2"
                  >
                    <el-form
                      ref="ruleForm1"
                      :model="ruleForm1"
                      :rules="rules"
                      style="margin: 0px auto;"
                    >
                      <!--学工号-->
                      <el-col
                        :span="24"
                        style="margin: 20px auto; height: 60px; display: flex;"
                      >
                        <el-form-item
                          label="学号/工号"
                          label-width="120px"
                          prop="studentNumber"
                        >
                          <el-input
                            v-model="ruleForm1.studentNumber"
                            type="text"
                            placeholder="请输入学号/六位工号"
                            style="width: 240px;"
                          />
                        </el-form-item>
                      </el-col>
                      <!--下一步-->
                      <el-col
                        :span="24"
                        style="margin: 0px auto; height: 60px; display: flex;"
                      >
                        <span class="main-below-center-div2-span1">
                          <el-button type="primary" @click="nextStep1">下一步</el-button>
                        </span>
                      </el-col>
                    </el-form>
                  </div>

                  <!--表单-->
                  <div
                    class="main-below-center-div2"
                  >
                    <el-form
                      ref="ruleForm"
                      :model="ruleForm"
                      style="margin: 0px auto;"
                    >

                      <!--手机号验证-->
                      <el-col
                        v-if="conceal3"
                        :span="24"
                        style="margin: 0px auto; height: 140px; display: flex;"
                      >
                        <my-verification-of-mobile-phone
                          ref="phone"
                          :is-disable-phone="true"
                          :phone-value="phoneValue"
                          :label-title1="labelTitle1"
                          :placeholder-phone="placeholderPhone"
                          style="margin: 0px auto;"
                          @returnedValue="returnedValue"
                          @getCode="getCode"
                        />

                      </el-col>
                      <!--下一步-->
                      <el-col
                        v-if="conceal3"
                        :span="24"
                        style="margin: 0px auto; height: 60px; display: flex;"
                      >
                        <span class="main-below-center-div2-span1">
                          <el-button type="primary" @click="nextStep">下一步</el-button>
                        </span>
                      </el-col>
                      <!--提示-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="height: 40px;text-align: center;color: rgba(245,108,108,0.8);"
                      >
                        密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符
                      </el-col>
                      <!--新密码-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="height: 60px;display: flex"
                      >
                        <el-form-item
                          style="margin: 0px auto;"
                          label="新密码"
                          label-width="120px"
                          prop="newPwd"
                          :rules="[
                            {
                              required: true,
                              message: '请输入内容',
                              trigger: 'change'
                            },
                            {
                              validator: validateNewPwd,
                              trigger: 'blur'
                            }
                          ]"
                        >
                          <el-input
                            v-model="ruleForm.newPwd"
                            type="password"
                            placeholder="请输入新密码"
                            style="width: 240px;"
                            @input="monitor(ruleForm.newPwd)"
                          /><!--@input 实时刷新数据-->
                        </el-form-item>

                      </el-col>
                      <!--密码强度-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="height: 30px;display: flex;padding-left: 120px"
                      >
                        <div class="main-below-center-div2-span2">
                          <div :class="styleSheet1" />
                          <div :class="styleSheet2" />
                          <div :class="styleSheet3" />
                          <div class="main-below-center-div2-span2-styleSheet">{{ styleSheet }}</div>
                        </div>
                      </el-col>
                      <!--确认新密码-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="height: 60px;display: flex"
                      >
                        <el-form-item
                          style="margin: 0px auto;"
                          label="确认新密码"
                          label-width="120px"
                          prop="newPwd2"
                          :rules="[
                            {
                              required: true,
                              message: '请输入内容',
                              trigger: 'change'
                            },
                            {
                              validator: validateNewPwd2,
                              trigger: 'blur'
                            }
                          ]"
                        >
                          <el-input
                            v-model="ruleForm.newPwd2"
                            type="password"
                            placeholder="请再次输入新密码"
                            style="width: 240px;"
                          />
                        </el-form-item>
                      </el-col>
                      <!--确认-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="margin: 0px auto; height: 60px; display: flex;"
                      >
                        <span class="main-below-center-div2-span1">
                          <el-button type="primary" @click="confirm">确 认</el-button>
                        </span>
                      </el-col>
                    </el-form>

                    <!--修改完成-->
                    <el-col
                      v-if="conceal5"
                      :span="24"
                      style="height: 240px; display: flex;"
                    >
                      <div class="main-below-center-div2-img">
                        <i class="main-below-center-div2-img-i1 iconfont icon-wancheng" />
                        <label class="main-below-center-div2-img-i2">修改成功</label>
                      </div>
                    </el-col>

                    <!--完成-->
                    <el-col
                      v-if="conceal5"
                      :span="24"
                      style="height: 50px; display: flex;"
                    >
                      <span class="main-below-center-div2-span1">
                        <el-button type="primary" style="width: 100%;" @click="previousPage">完 成</el-button>
                      </span>
                    </el-col>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!--<el-tab-pane label="申诉" name="appeal" disabled="true"></el-tab-pane>-->
          </el-tabs>
        </el-col>
      </div>
    </div>
  </div>
</template>

<script>

/*
*api
*/
import { checkSms, sendSms, updatePwd, checkWorkNo } from '../../api';

/*
* 手机验证组件
*/
import MyVerificationOfMobilePhone from '../../components/myVerificationOfMobilePhone/index2';

/*
* 正则校验
*/
import {
  isNewPwdBlank,
  isNewPwdStrength,
  isNewPwdChinese,
  isNewPwdStrengthStronger
} from '../../util/validate';

export default {
  // import引入的组件需要注入到对象中才能使用",
  components: { MyVerificationOfMobilePhone },
  data() {
    // 这里存放数据",
    return {
      labelTitle1: '预留手机号',
      placeholderPhone: '请输入预留的手机号',
      phoneValue: '',
      activeName: 'phoneBack',
      backdrop: 'orange', // orange 红背景白圈白字, white 白背景黑圈黑子
      backdrop1: 'white',
      backdrop2: 'white',
      backdrop3: 'white',
      conceal1: true,
      conceal3: true,
      conceal4: false,
      conceal5: false,
      styleSheet: '', // 较弱,一般,较强
      styleSheet1: 'gray', // gray 初色灰色, red1 低红色 red2 中红色, red3 高红色
      styleSheet2: 'gray',
      styleSheet3: 'gray',

      phone: '',
      valid: '',
      ruleForm1: {
        studentNumber: ''// 学工号
      },
      ruleForm: {
        mobileNumber: '',
        code: '',
        newPwd: '',
        newPwd2: '',
        passCode: ''
      },
      rules: {
        studentNumber: [
          { required: true, message: '请输入内容', trigger: 'change' }
        ]
      }
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    // 实时校验密码
    monitor(pwd) {
      this.styleSheet = '';
      this.styleSheet1 = 'gray';
      this.styleSheet2 = 'gray';
      this.styleSheet3 = 'gray';
      if (isNewPwdStrengthStronger(pwd)) {
        this.styleSheet = '较强';
        this.styleSheet1 = 'red1';
        this.styleSheet2 = 'red2';
        this.styleSheet3 = 'red3';
      } else if (isNewPwdStrength(pwd)) {
        this.styleSheet = '一般';
        this.styleSheet1 = 'red1';
        this.styleSheet2 = 'red2';
      } else if (pwd === '') {
        this.styleSheet = '';
        this.styleSheet1 = 'gray';
        this.styleSheet2 = 'gray';
        this.styleSheet3 = 'gray';
      } else {
        this.styleSheet1 = 'red1';
        this.styleSheet = '较弱';
      }
    },
    // 学工号验证下一步
    nextStep1() {
      let that = this;
      that.$refs['ruleForm1'].validate(valid => {
        if (valid) {
          checkWorkNo({
            workNo: that.ruleForm1.studentNumber
          }).then(res => {
            const { code, data, msg } = res.data;
            if (code === 200) {
              // let data = res.data.data.split("");
              // if (data.length > 0) {
              //   data.forEach((item, index) => {
              //     if (index === 3 || index === 4 || index === 5 || index === 6) {
              //       data[index] = "*";
              //     }
              //   });
              // }
              that.phoneValue = data.phone;
              that.phone = data.text;
              that.backdrop1 = 'orange';
              that.conceal1 = false;
              that.conceal3 = true;
            } else {
              that.$message.error(msg);
              return false;
            }
          });
        }
      });
    },
    /* 返回上一页*/
    previousPage() {
      history.go(-1);
    },
    /* 确认密码*/
    validateNewPwd2(rule, value, callback) {
      if (value) {
        if (this.ruleForm.newPwd) {
          if (value === this.ruleForm.newPwd) {
            callback();
          } else {
            callback(
              new Error('两次密码输入不一致，请重新输入')
            );
            this.$alert('两次密码输入不一致，请重新输入', '警告', {
              confirmButtonText: '确定'
            });
          }
        } else {
          callback(
            new Error('两次密码输入不一致，请重新输入')
          );
          this.$alert('两次密码输入不一致，请重新输入', '警告', {
            confirmButtonText: '确定'
          });
        }
      } else {
        callback();
      }
    },
    /* 校验新密码*/
    validateNewPwd(rule, value, callback) {
      if (value) {
        // this.monitor(value);
        if (isNewPwdBlank(value)) {
          if (isNewPwdChinese(value)) {
            if (isNewPwdStrength(value)) {
              callback();
            } else {
              callback(
                new Error('不符合密码规则，请确认后输入！')
              );
              this.$alert('不符合密码规则，请确认后输入！密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符', '警告', {
                confirmButtonText: '确定'
              });
            }
          } else {
            callback(
              new Error('格式错误，存在中文')
            );
          }
        } else {
          callback(
            new Error('格式错误，存在空格')
          );
        }
      } else {
        callback();
      }
    },
    /*
    * 新密码提交
    * 带上短信校验成功码，更新密码（找回密码）
    * /login/updatePwd
    * get
    * code=200成功，500非法访问
    */
    confirm() {
      let that = this;
      that.$refs['ruleForm'].validate(valid => {
        if (valid) {
          updatePwd({
            passCode: that.ruleForm.passCode,
            mobileNumber: that.phone,
            newPwd: that.ruleForm.newPwd2
          }).then(res => {
            if (res.data.code === 200) {
              that.$message.success(res.data.msg);
              that.backdrop3 = 'orange';
              that.conceal4 = false;
              that.conceal5 = true;
            } else {
              that.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
    /*
    * 手机校验
    * 获取已绑定手机号的验证码（找回密码）
    * /login/sendSms
    * get
    * code=200发送成功，404此手机号未绑定用户,500返回调用失败
    */
    getCode(phone) {
      let that = this;
      // that.phone = phone;
      sendSms({
        mobileNumber: that.phone
      }).then(res => {
        if (res.data.code === 200) {
          that.$message.success(res.data.msg);
        } else {
          that.$message.error(res.data.msg);
          return false;
        }
      });
    },
    /*
    * 调用子组件confirm方法
    * 返回：
    * 电话 phone，
    * 短信 message，
    * valid 是否校验成功
    */
    returnedValue(phone, message, valid) {
      this.valid = valid;
      this.ruleForm.mobileNumber = phone;
      this.ruleForm.code = message;
    },
    /*
    * 下一步校验
    * 校验验证码（找回密码）
    * login/checkSms
    * get
    * code=200通过,返回短信校验成功码，500已失效
    */
    nextStep() {
      let that = this;
      that.$refs.phone.confirm();
      if (that.valid) {
        checkSms({
          mobileNumber: that.phone,
          code: that.ruleForm.code
        }).then(res => {
          if (res.data.code === 200) {
            that.ruleForm.passCode = res.data.data;
            that.$message.success(res.data.msg);
            that.backdrop2 = 'orange';
            that.conceal3 = false;
            that.conceal4 = true;
          } else {
            that.$message.error(res.data.msg);
            return false;
          }
        });
        /* if(that.phone !== "") {
          if(that.phone === that.ruleForm.mobileNumber) {
            checkSms({
              mobileNumber : that.ruleForm.mobileNumber,
              code  : that.ruleForm.code
            }).then(res =>{
              if(res.data.code === 200) {
                that.ruleForm.passCode = res.data.data;
                that.$message.success(res.data.msg);
                that.backdrop2 = "orange";
                that.conceal3 = false;
                that.conceal4 = true;
              } else {
                that.$message.error(res.data.msg);
                return false;
              }
            });

          } else {
            this.$message.warning('手机号被修改，请重新校验！');
            return false;
          }
        } else {
          that.$message.warning('请先获取验证码，并输入正确的验证码！');
          return false;
        }*/
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.gray {
  width: 60px;
  height: 15px;
  background-color: rgba(119, 136, 153, 0.2);
  margin-right: 6px;
  margin-top: 1px;
}

.red1 {
  width: 60px;
  height: 15px;
  background-color: rgba(245, 108, 108, 0.4);
  margin-right: 6px;
  margin-top: 1px;
}

.red2 {
  width: 60px;
  height: 15px;
  //background-color: rgba(255,165,0,0.7);//goldEnrod
  background-color: rgba(245, 108, 108, 0.7);
  margin-right: 6px;
  margin-top: 1px;
}

.red3 {
  width: 60px;
  height: 15px;
  //background-color: rgba(103,194,58,0.9);//green
  background-color: rgba(245, 108, 108, 1);
  margin-right: 6px;
  margin-top: 1px;
}

.orange {
  border: 1px solid rgba(199, 25, 33, 0.8);
  background-color: rgba(199, 25, 33, 0.8);
  color: rgba(255, 255, 255, 0.9);
}

.white {
  background-color: rgba(255, 255, 255, 0.8);
  color: rgba(43, 42, 51, 0.9);
  border: 1px solid rgba(43, 42, 51, 0.9);
}

/deep/ .el-form-item__label {
  font-size: 15px;
}

/deep/ .el-tabs__item {
  font-size: 18px !important;
  width: 300px;
}

/deep/ .el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}

/deep/ .el-tabs__item.is-active {
  background-color: rgba(199, 25, 33, 0.1);
}

#recoverPwd {
  height: 100%;
  width: 100%;

  .header {
    height: 150px;
    width: 100%;
    background-image: url("../../assets/img/login/beijin1.png");
    background-position: center;
    background-size: 100% 100%;

  }

  .main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-image: url("../../assets/img/login/beijin2.jpg");
    background-position: center;
    background-size: 100% 100%;

    &-up {
      width: 100%;
      height: 60px;
      display: flex;
      color: rgba(255, 255, 255, 0.9);
      font-size: 14px;
      background-color: #c03338;
      position: relative;

      &-left {
        position: absolute;
        left: 30px;
        width: 100px;
        height: 60px;
        line-height: 60px;

      }

      &-right {
        position: absolute;
        top: 50%;
        right: 30px;
        width: 70px;
        height: 30px;
        transform: translate(0%, -50%);
        display: flex;
        cursor: pointer; //鼠标变手指
        color: white;

        &-i1 {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          cursor: pointer; //鼠标变手指
        }

        &-i2 {
          height: 30px;
          line-height: 30px;
          cursor: pointer; //鼠标变手指
        }
      }
    }

    &-below {
      width: 80%;
      height: 60%;
      display: flex;
      margin: 80px auto;
      padding: 50px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .14);

      &-tabs {
        height: 400px;
        width: 99%;
        margin: 0px auto;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);

      }

      &-center {
        height: 370px;
        width: 600px;
        margin: 0px auto;

        &-div1 {
          height: 100px;
          width: 100%;
          position: relative;

          &-label {
            width: 120px;
            height: 30px;
            position: absolute;
            left: 0%;
            top: 50%;
            transform: translate(0%, -50%);
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }

            &-i2 {
              width: 80px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }

          &-label1 {
            width: 120px;
            height: 30px;
            position: absolute;
            left: 28%;
            top: 50%;
            transform: translate(0%, -50%);
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }

            &-i2 {
              width: 80px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }

          &-label3 {
            width: 120px;
            height: 30px;
            position: absolute;
            left: 67%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }

            &-i2 {
              width: 80px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }

          &-label5 {
            width: 90px;
            height: 30px;
            position: absolute;
            right: 0%;
            top: 50%;
            transform: translate(0%, -50%);
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }

            &-i2 {
              width: 50px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }

          &-label0 {
            border-bottom: 0.1px solid rgba(43, 42, 51, 0.3);
            width: 30px;
            position: absolute;
            left: 22.5%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &-label2 {
            border-bottom: 0.1px solid rgba(43, 42, 51, 0.3);
            width: 30px;
            position: absolute;
            left: 50.5%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &-label4 {
            border-bottom: 0.1px solid rgba(43, 42, 51, 0.3);
            width: 30px;
            position: absolute;
            right: 16%;
            top: 50%;
            transform: translate(-50%, 50%);
          }
        }

        &-div2 {
          display: flex;
          height: 300px;
          width: 100%;
          flex-wrap: wrap;

          &-img {
            width: 120px;
            height: 150px;
            margin: 30px auto;
            display: flex;
            flex-wrap: wrap;

            &-i1 {
              color: rgba(199, 25, 33, 1);
              font-size: 60px;
              width: 120px;
              height: 120px;
              line-height: 120px;
              text-align: center;

            }

            &-i2 {
              color: rgba(43, 42, 51, 0.9);
              font-size: 15px;
              font-weight: 600;
              width: 120px;
              height: 30px;
              line-height: 30px;
              text-align: center;

            }
          }

          &-span {
            color: rgba(199, 25, 33, 0.6);
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            width: 120px;
            height: 40px;
            cursor: pointer; //鼠标变手指
          }

          &-span2 {
            margin: 0px auto;
            width: 240px;
            height: 20px;
            display: flex;
            flex-wrap: wrap;

            &-styleSheet {
              width: 40px;
              height: 20px;
              color: #F56C6C;
              text-align: center;
              font-size: 12px;
            }
          }

          &-span1 {
            width: 120px;
            height: 60px;
            margin: 0px auto;
            color: rgba(199, 25, 33, 0.6);
            line-height: 60px;
            text-align: center;
            font-size: 14px;
            cursor: pointer; //鼠标变手指
          }

        }
      }
    }
  }
}
</style>
